// 
// user.js
// Use this to write your custom JS
//


function pageCarousel(){
	var pages=[
		'index.html',
		'customers.html',
		'partners.html',
		'platform-technology.html',
		'software-sector-security-defense.html',
		'platform-integrations.html',
	];
	var url=location.href;
	var currentPageName = url.substring(url.lastIndexOf('/')+1).split('#')[0];
	var currentPageIndex = 0;
	var nextPageIndex = 0;
	currentPageIndex = pages.indexOf(currentPageName);

	//if we can't find currentPage in list, we start with index.html
	if (currentPageIndex < 0) {
		currentPageIndex = 0;
	}
	
	//grab next page in pages-array
	nextPageIndex = currentPageIndex+1;

	if (nextPageIndex >= pages.length) {
		//start at the beginning, when last page of array
		nextPageIndex = 0;
	}	
	setTimeout(function(){ window.location = pages[nextPageIndex]+'#pageCarousel-'+window.switchtime; }, window.switchtime);
}
window.switchtime = null;
const hash = window.location.hash || "";
if (hash.indexOf("pageCarousel") === 1) {
	window.switchtime = window.location.hash.split("-")[1] || 10000;
	
	document.getElementsByTagName('body')[0].classList.add('fadeIn');
	if (document.getElementById('rotorsbanner')) {
		document.getElementById('rotorsbanner').style.display = 'none';
	}
	pageCarousel();

}